import styles from "./Bouton.module.scss";
import { Link } from "react-router-dom";

export default function Bouton({ text, link }) {
  return (
    <section className={`${styles.bloc}`}>
      <Link to={link} className={`${styles.textBloc}`}>
        <p className={`${styles.text}`}>{text}</p>
      </Link>
    </section>
  );
}
