import React, { StrictMode } from "react";
// import { createRoot } from "react-dom/client";
import "./assets/styles/index.scss";
// import App from "./App";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { hydrate, render } from "react-dom";



const rootElement = document.getElementById("root");
// const root = createRoot(rootElement);
const App = () =>
  <StrictMode>
    <RouterProvider router={router}></RouterProvider>
  </StrictMode>;


if (rootElement.hasChildNodes()) {
  hydrate(App(), rootElement);
} else {
  render(App(), rootElement);
}
