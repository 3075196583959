import CarouselImg from "../Carousel/CarouselImg";
import styles from "./Bloc.module.scss";
export default function Text({ text, images }) {
  return (
    <div className={`${styles.Container_photo_portfolio}`}>
      <div className={`${styles.Container_bloc_photo}`}>
        <div className={`${styles.Content}`}>
          <h2 className={`${styles.text}`}>{text}</h2>
          {/* {images.map((img) => (
            <img src={img.url} alt={img.alt} />
          ))} */}

          <CarouselImg images={images} />
        </div>
      </div>
    </div>
  );
}
