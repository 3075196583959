import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer>
      <div className={`${styles.bloc}`}>
        <img src="/logo.png" alt="logo" className={`${styles.img}`} />
        <Link to="/mentions-legales" className={`${styles.links}`}>Mentions Legales</Link>
        <div className={`${styles.p}`}>
          <p>©Lomatout Faire | Tous droits réservés</p>
        </div>
      </div>
    </footer>
  );
}
