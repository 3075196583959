import Menu from "../Menu/Menu";
import "./Header.module.scss";
import styles from "./Header.module.scss";
import { Link } from "react-router-dom";

export default function Header({ checked, setChecked }) {

  function handleCheck() {
    console.log("click");
    console.log(checked);
    setChecked((prev) => !prev);
  }

  return (
    <>
      <header className={`d-flex align-items-center ${checked && styles.openedHeader}`}>
        <Link to="/"><img src="/logo.png" alt="logo" /></Link>
        <div className={styles.container}>
          <label htmlFor={styles.check} className={styles.burgerBtncontainer}>
            <input type="checkbox" name="check" id={styles.check} onChange={handleCheck} checked={checked} />
            <div className={styles.lines}>
              <div className={styles.line}></div>
              <div className={styles.line}></div>
              <div className={styles.line}></div>
            </div>
          </label>
        </div>
      </header>
      {checked && (<Menu setChecked={setChecked}></Menu>)}
    </>
  );
}
