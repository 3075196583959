import { useRouteError } from "react-router-dom";
import Bouton from "../../components/Bouton/Bouton";
import styles from "./ErrorPage.module.scss";

export default function ErrorPage() {
  const error = useRouteError();
  return (
    <div className={`${styles.allBloc}`}>
      <div className={`${styles.bloc}`}>
        <div className={`${styles.blocTitle} ${styles.item}`}>
          <img src="/logo.png" alt="logo" className={`${styles.title}`} />
          <p>Lomatout Faire</p>
        </div>
        <h2 className={` ${styles.item}`}>Stop !</h2>
        <Bouton text="Revenir à la page d'accueil" link="/" />
        <h1 className={` ${styles.item}`}>Erreur: {error.statusText}</h1>
      </div>
    </div>
  );
}
