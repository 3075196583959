import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import Homepage from "./pages/homepage/Homepage";
import Portfolio from "./pages/portfolio/Portfolio";
import Contact from "./pages/contact/Contact";
import Mentionslegales from "./pages/mentionslegales/Mentionslegales";
import ErrorPage from "./pages/errorPage/ErrorPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        path: "/",
        element: <Homepage />,
      },
      {
        path: "/portfolio",
        caseSensitive: false,
        element: <Portfolio />,
      },
      {
        path: "/contact",
        caseSensitive: false,
        element: <Contact />,
      },
      {
        path: "/mentions-legales",
        caseSensitive: false,
        element: <Mentionslegales/>,
      },
    ],
  },
]);
