import Title from "../../components/title/Title";
// import Form from "../../components/Form/Form";
import EzContact from "../../components/EzContact/EzContact";

import styles from "./Contact.module.scss";

export default function Contact() {
  return (
    <div>
      <Title text="Contact" />
      <div className={`${styles.blocContactForm}`}>
        <EzContact />
          {/* { <div className={`${styles.blocForm}`}>
          {/* <h2 className={`${styles.blocFormTitle}`}>Formulaire de Contact</h2> */}
          {/* <Form /> */}
        {/* </div> } */} 
      </div>
    </div>
  );
}
