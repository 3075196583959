import styles from "./Mentionslegales.module.scss";
import { Link } from "react-router-dom";

export default function MentionsLegales() {
  return (
    <div className={`${styles.allBloc}`}>
      <div className={`${styles.blocTitle}`}>
        <h2 className={`${styles.title}`}>1 - Édition du site</h2>
        <div className={`${styles.pm}`}></div>
      </div>
      <p className={`${styles.text}`}>
        En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la
        confiance dans l'économie numérique, il est précisé aux utilisateurs du
        site internet{" "}
        <Link to="/" className={`${styles.links}`}>
          https://lomatoutfaire.be/
        </Link>{" "}
        l'identité des différents intervenants dans le cadre de sa réalisation
        et de son suivi: Propriétaire du site : (Nom et prénom renseigné à
        l’hebergeur) – – (Renseigné à l’hebergeur) - Contact :{" "}
        <Link to="mailto:info@lomatoutfaire.be" className={`${styles.links}`}>
          info@lomatoutfaire.be
        </Link>{" "}
        - Adresse : . Identification de l'entreprise : srl (Nom et prénom
        renseigné à l’hebergeur) – – (Renseigné à l’hebergeur) au capital social
        de € - SIREN : - RCS ou RM : - Adresse postale : - [Consignes : ajoutez
        ici le lien hypertexte vers la page de vos conditions générales de vente
        si vous en avez une] Directeur de la publication : (Nom et prénom
        renseigné à l’hebergeur) – – (Renseigné à l’hebergeur) - Contact :
        info@lomatoutfaire.be. Hébergeur : OVH SAS - 2 rue Kellermann - BP 80157
        - 59053 Roubaix Cedex 1 - Téléphone : 1007 Délégué à la protection des
        données : (Nom et prénom renseigné à l’hebergeur) – – (Renseigné à
        l’hebergeur) - Autres contributeurs :{" "}
      </p>
      <div className={`${styles.blocTitle}`}>
        <h2 className={`${styles.title}`}>
          2 - Propriété intellectuelle et contrefaçons
        </h2>
        <div className={`${styles.pm}`}></div>
      </div>
      <p className={`${styles.text}`}>
        (Nom et prénom renseigné à l’hebergeur) – – (Renseigné à l’hebergeur)
        est propriétaire des droits de propriété intellectuelle et détient les
        droits d’usage sur tous les éléments accessibles sur le site internet,
        notamment les textes, images, graphismes, logos, vidéos, architecture,
        icônes et sons. Toute reproduction, représentation, modification,
        publication, adaptation de tout ou partie des éléments du site, quel que
        soit le moyen ou le procédé utilisé, est interdite, sauf autorisation
        écrite préalable de (Nom et prénom renseigné à l’hebergeur) – –
        (Renseigné à l’hebergeur). Toute exploitation non autorisée du site ou
        de l’un quelconque des éléments qu’il contient sera considérée comme
        constitutive d’une contrefaçon et poursuivie conformément aux
        dispositions des articles L.335-2 et suivants du Code de Propriété
        Intellectuelle.
      </p>
      <div className={`${styles.blocTitle}`}>
        <h2 className={`${styles.title}`}>3 - Limitations de responsabilité</h2>
        <div className={`${styles.pm}`}></div>
      </div>
      <p className={`${styles.text}`}>
        (Nom et prénom renseigné à l’hebergeur) – – (Renseigné à l’hebergeur) ne
        pourra être tenu pour responsable des dommages directs et indirects
        causés au matériel de l’utilisateur, lors de l’accès au site
        <Link to="/" className={`${styles.links}`}>
          https://lomatoutfaire.be/
        </Link>
      . (Nom et prénom renseigné à l’hebergeur) – – (Renseigné à l’hebergeur)
        décline toute responsabilité quant à l’utilisation qui pourrait être
        faite des informations et contenus présents sur
        {" "}
        <Link to="/" className={`${styles.links}`}>
          https://lomatoutfaire.be/
        </Link>{" "}. (Nom et prénom renseigné à l’hebergeur) – –
        (Renseigné à l’hebergeur) s’engage à sécuriser au mieux le site
        <Link to="/" className={`${styles.links}`}>
          https://lomatoutfaire.be/
        </Link>
        , cependant sa responsabilité ne pourra être mise en cause si des
        données indésirables sont importées et installées sur son site à son
        insu. Des espaces interactifs (espace contact ou commentaires) sont à la
        disposition des utilisateurs. (Nom et prénom renseigné à l’hebergeur) –
        – (Renseigné à l’hebergeur) se réserve le droit de supprimer, sans mise
        en demeure préalable, tout contenu déposé dans cet espace qui
        contreviendrait à la législation applicable en Belgique, en particulier
        aux dispositions relatives à la protection des données. Le cas échéant,
        (Nom et prénom renseigné à l’hebergeur) – – (Renseigné à l’hebergeur) se
        réserve également la possibilité de mettre en cause la responsabilité
        civile et/ou pénale de l’utilisateur, notamment en cas de message à
        caractère raciste, injurieux, diffamant, ou pornographique, quel que
        soit le support utilisé (texte, photographie …).{" "}
      </p>
      <div className={`${styles.blocTitle}`}>
        <h2 className={`${styles.title}`}>
          4 - CNIL et gestion des données personnelles
        </h2>
        <div className={`${styles.pm}`}></div>
      </div>
      <p className={`${styles.text}`}>
        Conformément aux dispositions de la loi 78-17 du 6 janvier 1978
        modifiée, l’utilisateur du site{" "}
        <Link to="/" className={`${styles.links}`}>
          https://lomatoutfaire.be/
        </Link>{" "}
        dispose d’un droit d’accès, de modification et de suppression des
        informations collectées. Pour exercer ce droit, envoyez un message à
        notre Délégué à la Protection des Données : (Nom et prénom renseigné à
        l’hebergeur) – – (Renseigné à l’hebergeur) - . Pour plus d'informations
        sur la façon dont nous traitons vos données (type de données, finalité,
        destinataire...), lisez notre{" "}
        <Link to="/mentions-legales" className={`${styles.links}`}>
          https://lomatoutfaire.be/mentions-legales{" "}
        </Link>
        . [Consignes : ajoutez ici le lien hypertexte vers votre politique de
        confidentialité]
      </p>
      <div className={`${styles.blocTitle}`}>
        <h2 className={`${styles.title}`}>5 - Liens hypertextes et cookies</h2>
        <div className={`${styles.pm}`}></div>
      </div>
      <p className={`${styles.text}`}>
        Le site{" "}
        <Link to="/" className={`${styles.links}`}>
          https://lomatoutfaire.be/
        </Link>{" "}
        contient des liens hypertextes vers d’autres sites et dégage toute
        responsabilité à propos de ces liens externes ou des liens créés par
        d’autres sites vers{" "}
        <Link to="/" className={`${styles.links}`}>
          https://lomatoutfaire.be/
        </Link>
        . La navigation sur le site
        <Link to="/" className={`${styles.links}`}>
          https://lomatoutfaire.be/
        </Link>{" "}
        est susceptible de provoquer l’installation de cookie(s) sur
        l’ordinateur de l’utilisateur. Un "cookie" est un fichier de petite
        taille qui enregistre des informations relatives à la navigation d’un
        utilisateur sur un site. Les données ainsi obtenues permettent d'obtenir
        des mesures de fréquentation, par exemple. Vous avez la possibilité
        d’accepter ou de refuser les cookies en modifiant les paramètres de
        votre navigateur. Aucun cookie ne sera déposé sans votre consentement.
        Les cookies sont enregistrés pour une durée maximale de mois. Pour plus
        d'informations sur la façon dont nous faisons usage des cookies, lisez
        notre
        <Link to="/mentions-legales" className={`${styles.links}`}>
          https://lomatoutfaire.be/mentions-legales
        </Link>
        . [Consignes : ajoutez ici le lien hypertexte vers votre politique de
        confidentialité ou vers votre politique de cookies si vous en avez une
        (c'est le cas si vous utilisez Complianz)]
      </p>
      <div className={`${styles.blocTitle}`}>
        <h2 className={`${styles.title}`}>
          6 - Droit applicable et attribution de juridiction
        </h2>
        <div className={`${styles.pm}`}></div>
      </div>
      <p className={`${styles.text}`}>
        Tout litige en relation avec l’utilisation du site
        <Link to="/" className={`${styles.links}`}>
          {" "}
          https://lomatoutfaire.be/
        </Link>{" "}
        est soumis au droit belge. En dehors des cas où la loi ne le permet
        pas, il est fait attribution exclusive de juridiction aux tribunaux
        compétents de Liege.
      </p>
    </div>
  );
}
