import "./Menu.module.scss";
import styles from "./Menu.module.scss";


import { Link } from "react-router-dom";

export default function Menu({setChecked}) {
    return (
        <div className={styles.container}>
            <nav>
                <ul>
                    <li><Link to="/" onClick={() => setChecked(false)}>Accueil</Link></li>
                    <li><Link to="/portfolio" onClick={() => setChecked(false)}>Portfolio</Link></li>
                    <li><Link to="/contact" onClick={() => setChecked(false)}>Contact</Link></li>
                </ul>
            </nav>
        </div>
    )
}