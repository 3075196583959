import styles from "./Title.module.scss";
export default function Title({ text }) {
  return (
    <div className={`${styles.Containerh1}`}>
      <div>
        <h1>{text}</h1>
        <div className={`${styles.divSvg}`}>
          <img
            src="/images/soulignement.svg"
            alt="le soulignement"
            className={`${styles.Svg}`}
          />
        </div>
      </div>
    </div>
  );
}
