import styles from "./Portfolio.module.scss";
import Title from "../../components/title/Title";
import Bloc from "../../components/bloc/Bloc";

import { useState, useEffect } from "react";


async function getPortfolio() {
  const response = await fetch("/portfolio.json");
  const data = await response.json();
  return data;
}

export default function Portfolio() {
  const [portfolio, setPortfolio] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getPortfolio();
      setPortfolio(data);
    };
    fetchData();
  }, []);


  return (
    <div className={`${styles.Containerh1}`}>
      <Title text="Portfolio" />
      <div className={`${styles.Container_photo_portfolio}`}>
        <div className={`${styles.Container_bloc_photo}`}>
          <div className={`${styles.Container_bloc}`}>
            {portfolio.map((item) => (
              <Bloc key={item.nom} text={item.nom} images={item.images} />
            ))}
{/*             <Bloc text="Peinture" />
            <Bloc text="Argil" />
            <Bloc text="Parquet" />
            <Bloc text="Claustra" />
            <Bloc text="Plaque de plâtre" />
            <Bloc text="Enduit" />
            <Bloc text="Faux plafond" />
            <Bloc text="CLoisons" />
            <Bloc text="Contre cloisons" />
            <Bloc text="Pool house" /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
