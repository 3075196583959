import styles from "./EzContact.module.scss";

export default function EzContact() {
  return (
    <div className={`${styles.allBloc}`}>
      <div className={`${styles.bloc}`}>
        <div className={`${styles.blocTitle}`}>
          <img src="/logo.png" alt="logo" className={`${styles.logo}`} />
          <p className={`${styles.text}`}>Lomatout Faire</p>
        </div>
        <div className={`${styles.allBlocListe}`}>
          <ul className={`${styles.blocListe}`}>
            <a href="tel:+32(0)494 31 12 01">
              <li className={`${styles.itemListe}`}>
                Téléphone :
                <span className={`${styles.itemListeText}`}>
                  +32(0)494 31 12 01
                </span>
              </li>
            </a>
            <a href="mailto:info@lomatoutfaire.be">
              <li className={`${styles.itemListe}`}>
                Email :
                <span className={`${styles.itemListeText}`}>
                  info@lomatoufaire.be
                </span>
              </li>
            </a>
          </ul>
        </div>
        <div className={`${styles.blocLogoSocial}`}>
          <a href="https://www.facebook.com/profile.php?id=100083274981676">
            <img
              src="/images/facebookLogo.png"
              alt="logo"
              className={`${styles.logoSocial}`}
            />
          </a>
          <a href="https://www.instagram.com/lomatout_faire">
            <img
              src="/images/instagramLogo.png"
              alt="logo"
              className={`${styles.logoSocialDernier}`}
            />
          </a>
        </div>
        <p className={`${styles.textSocial}`}>
          Cliquez ci dessus pour découvrir nos réseaux
        </p>
      </div>
    </div>
  );
}
