import React from "react";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import styles from "./App.scss";
import { Outlet } from "react-router-dom";
import "./assets/styles/index.scss";

export default function App() {
  const [checked, setChecked] = React.useState(false);

  return (
    <div className={`d-flex flex-column ${styles.appContainer}`}>
      <Header checked={checked} setChecked={setChecked} />
      {!checked &&
        <>
          <div className="flex-fill" id="a">
            <Outlet />
          </div>
          <Footer />
        </>
      }
    </div>
  );
}
