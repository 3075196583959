import { useState, useEffect } from "react";

import styles from "./Homepage.module.scss";
import Bouton from "../../components/Bouton/Bouton";

import Bloc from "../../components/bloc/Bloc";

async function getPortfolio() {
  const response = await fetch("/portfolio.json");
  const data = await response.json();
  return data;
}

export default function Homepage() {
  const [portfolio, setPortfolio] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getPortfolio();
      setPortfolio(data);
    };
    fetchData();
  }, []);

  return (
    <div className={`${styles.GigaContainer}`}>
      <div className={`${styles.Container1}`}>
        <div className={`${styles.blocText}`}>
          <p className={`${styles.text}`}>Votre projet,</p>
          <p className={`${styles.text}`}>Notre travail</p>
        </div>
        <div className={`${styles.blocParagraph}`}>
          <p className={`${styles.paragraph}`}>
            La société Lomatout faire est le partenaire idéal dans la rénovation de
            votre habitation ! Que ce soit un nouveau bien ou une ancienne
            demeure, une rénovation complète ou partielle, nous mettons tout en
            œuvre pour vous aider à réaliser vos rêves et faire de votre maison,
            votre cocon.
          </p>
          <p className={`${styles.paragraph}`}>
            Nous effectuons un large panel de travail,
            de réalisations plus spécifiques aux créations, montages,
            applications ainsi que réparations, nos services seront à la hauteur de vos
            espérances.
          </p>
        </div>
        <div className={`${styles.bouton} ${styles.bouton_margin}`}>
          <Bouton text="Contactez-nous" link="/contact" />
        </div>
      </div>
      <div className={`${styles.Container2}`}>
        <h2 className={styles.subtitle}>Nos services</h2>
        <div className={`${styles.Container_bloc}`}>
          {portfolio
            .filter((item) => item.homepage === true)
            .map((item) => (
              <Bloc key={item.nom} text={item.nom} images={item.images} />
            ))}
        </div>
        <div className={`${styles.bouton}`} style={{ marginBlock: "1rem" }}>
          <Bouton text="Voir plus" link="/portfolio" />
        </div>
      </div>
    </div>
  );
}
