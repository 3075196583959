import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import style from './CarouselImg.module.scss';

const CarouselImg = ({images}) => {
  return (
    <div className={style.carouselContainer}>
      <Carousel showThumbs={false} showStatus={false} centerSlidePercentage={true}>
        {images.map((image) => (
        <div key={image.url}>
          <img src={image.url} alt={image.alt} />
        </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselImg;
